<template>
  <div>
    <SettingsTitle title="Labels"/>
    <div class="w-full flex">
      <div class="w-2/6 border-r border-grey-darker-200 py-5">
        <span class="font-sansDemi base-font-gray-200 text-xl leading-none">Private Labels</span>
      </div>
      <div class="w-4/6">
        <Loader 
          v-if="loading"
          :animation="true"
          classes="w-8 h-8 ml-8 mt-3"
        />
        <template v-else>
          <ul v-if="privateLabels">
            <li 
              v-for="privateLabel in privateLabels"
              :key="privateLabel.id"
              class="flex flex-wrap items-center justify-between py-5 pl-8 cursor-pointer border-b border-dashed border-grey-darker-200"
            >
              <span>{{ privateLabel.name }}</span>
              <button 
                @click="manage(privateLabel.id)"
                class="border-grey-darker-100 border rounded-3xl px-2 py-1 text-sm font-monoDemi font-medium"
              >
                View
              </button>
            </li>
          </ul>
          <p 
            v-else
            class="font-sansDemi base-font-gray-200 text-xl leading-none py-5 pl-8"
          >
            No Private labels
          </p>
        </template>
        
      </div>
    </div>
    <div class="w-full flex">
      <div class="w-2/6 border-r border-grey-darker-200 py-5">
        <span class="font-sansDemi base-font-gray-200 text-xl leading-none">Team Labels</span>
      </div>
      <div class="w-4/6">
        <Loader 
          v-if="loading"
          :animation="true"
          classes="w-8 h-8 ml-8 mt-3"
        />
        <template v-else>
          <ul v-if="teamLabels">
            <li 
              v-for="teamLabel in teamLabels"
              :key="teamLabel.id"
              class="flex flex-wrap items-center justify-between py-5 pl-8 cursor-pointer border-b border-dashed border-grey-darker-200"
            >
              <span>{{ teamLabel.name }}</span>
              <button 
                @click="manage(teamLabel.id)"
                class="border-grey-darker-100 border rounded-3xl px-2 py-1 text-sm font-monoDemi font-medium"
              >
                View
              </button>
            </li>
          </ul>
          <p 
            v-else
            class="font-sansDemi base-font-gray-200 text-xl leading-none py-5 pl-8"
          >
            No Team labels
          </p>
        </template>
        
      </div>
    </div>
    <div class="w-full flex">
      <div class="w-2/6"></div>
      <div class="w-4/6">
        <button 
          @click="addNewLabel"
          class="border-grey-darker-100 border rounded-3xl px-2 py-1 text-sm font-monoDemi font-medium mt-6 ml-4"
        >
          Add a New Label
        </button>
      </div>
    </div>
        
  </div>
</template>

<script>
  import SettingsTitle from '@/components/settings/SettingsTitle'
  import Loader from '@/components/Loader'
  import { getLabels } from '@/helpers/Queries'
  import { mapGetters } from 'vuex'

  export default {
    components: { 
      SettingsTitle,
      Loader
    },
    data(){
      return {
        teamLabels: null,
        privateLabels: null,
        loading: false
      }
    },
    computed:{
      ...mapGetters({
        user_meta: 'general/user_meta'
      })
    },
    methods: {
      manage(data){
        alert(data)
      },
      addNewLabel(){
        this.$emit('addNewLabel')
      },
      async getPrivateLabels(){
        const result = await this.$mainClient.query(getLabels(this.user_meta.id))
        if(result.data.getLabels.items.length){
          this.privateLabels = JSON.parse(JSON.stringify(result.data.getLabels.items))
        }
      },
      async getTeamLabels(){
        let teamsResult = ''
        this.user_meta.teams.forEach(async elem => {
          teamsResult = await this.$mainClient.query(getLabels(elem.id))
          this.teamLabels.push(...teamsResult.data.getLabels.items)
        });
      },
    },
    async mounted(){
      this.loading = true
      await this.getPrivateLabels()
      if(this.user_meta.teams && this.user_meta.teams.length){
        await this.getTeamLabels()
      }
      this.loading = false
    }
  }
</script>

<style lang="scss" scoped>

</style>