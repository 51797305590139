<template>
  <div class="relative">
    <closeIcon class="cursor-pointer absolute top-0 -right-4" @close="close"/>

    <p class="text-2xl text-black font-bold mb-16 pt-3 mb-6">Add a New Label</p>
    <form 
      @submit.prevent="addLabel"
      class="flex flex-wrap items-center mb-7"
    >
      <label for="labelName" class="text-base text-black pb-5">Label Name</label>
      <input 
        v-model="labelName"
        type="text"
        id="labelName"
        placeholder="Type a label name here"
        autocomplete="off"
        class="w-full mb-8 base-bg-darker-300 youTubeUrl-input rounded px-4 h-8 font-mono text-sm text-black outline-none placeholder-black"
      >

      <p class="text-base text-black pb-5 border-b border-grey-light-600 w-full">Label Access</p>
      <div 
        @click="setLabelAccess(labelsList[0])"
        class="flex py-3 pl-4 w-full border-b border-grey-light-600 cursor-pointer"
        :class="{
          'purple-light-bg purple-text': labelAccess == labelsList[0]
        }"
      >
        <triangleInShield/>
        <span class="pl-3">Private</span>
      </div>
      <div 
        v-if="teamsList && teamsList.length"
        @click="setLabelAccess(labelsList[1])"
        class="flex flex-wrap py-3 pl-4 w-full border-b border-grey-light-600 cursor-pointer"
        :class="{
          'purple-light-bg purple-text': labelAccess == labelsList[1]
        }"
      >
        <div class="flex items-center">
          <circlesOnCircle />
          <span class="pl-3">Team</span>
        </div>
        <div 
          v-if="labelAccess == 'team_label'"
          class="team-bucket-select w-full pl-9 pt-3"
        >
          <multiselect 
            v-model="selectedTeam" 
            :options="teamsList"
            :searchable="false"
            label="name"
            selectLabel=""
            selectedLabel=""
            deselectLabel=""
            placeholder=""
            class="w-72 mr-4 bg-grey font-sans transcriptSelect border border-grey-light"
          >
            <template slot="option" slot-scope="props">
              <div class="option__desc font-sans text-base font-light py-1 mx-4 bg-transparent border-b border-dotted border-grey-light">
                {{ props.option.teamName }}
              </div>
            </template>
            <template slot="singleLabel" slot-scope="props">
              <div class="option__desc font-sans text-base font-light py-1 border-b border-dotted border-grey-light">
                {{ props.option.teamName }}
              </div>
            </template>
            <template slot="noResult">
              <div class="option__desc font-sans text-base font-light py-1 border-b border-dotted border-grey-light">
                No results.
              </div>
            </template>
            <template slot="noOptions">
              <div class="option__desc font-sans text-base font-light py-1 border-b border-dotted border-grey-light">
                List is empty.
              </div>
            </template>
          </multiselect>
        </div>
      </div>
      <div 
        v-else 
        class="flex flex-wrap py-3 pl-4 w-full border-b border-grey-light-600 cursor-pointer opacity-50"
      >
        <div class="flex items-center">
          <circlesOnCircle />
          <span class="pl-3">Team</span>
        </div>
      </div>

      <ButtonWithLoader 
        :disabled="loading"
        btnText="Add label"
        type="submit"
        bgClass="purple-bg"
        textClass="purple-light font-monoDemi text-sm"
        heigthClass="h-8"
        class="mt-6"
      />
    </form>
  </div>
</template>

<script>
  import closeIcon from '@/components/icons/close-icon'
  import ButtonWithLoader from '@/components/ButtonWithLoader'
  import triangleInShield from '@/components/icons/triangle-in-shield'
  import circlesOnCircle from '@/components/icons/circles-on-circle'
  import { warningToastConfig } from '@/helpers/constants'
  import { createLabel } from '@/helpers/Mutations'
  import ToastMessages from '@/data/toast_messages.json'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    components: {
      closeIcon,
      ButtonWithLoader,
      triangleInShield,
      circlesOnCircle
    },
    data(){
      return{
        labelName: '',
        labelAccess: 'personal_label',
        selectedTeam: null,
        loading: false,
        labelsList: ['personal_label', 'team_label']
      }
    },
    computed: {
      ...mapGetters({
        user_meta: 'general/user_meta'
      }),
      teamsList(){
        return this.user_meta.teams
      }
    },
    methods: {
      ...mapActions({
        getTeams: 'general/getTeams'
      }),
      setLabelAccess(arg){
        this.labelAccess = arg
      },
      async addLabel() {
        if(this.labelName.length){
          let modelData = {
            id: '',
            labelType: this.labelAccess,
            name: this.labelName
          }

          if(modelData.labelType == this.labelsList[1]){
            if(!this.selectedTeam){
              this.$toasted.show(ToastMessages.settings.noTeamSelected, warningToastConfig)
              this.loading = false
              return false
            }else{
              modelData.id = this.selectedTeam.id
            }
          }

          this.loading = true
          try {
            await this.$mainClient.mutate(createLabel(modelData))
            this.$toasted.show(`Label ${this.labelName} has been added`, warningToastConfig)
          } catch (error) {
            this.$toasted.show(error, warningToastConfig)
          }
          this.loading = false
        }else{
          this.$toasted.show(ToastMessages.settings.noLabelTitle, warningToastConfig)
        }
      },
      close(){
        this.$emit('close')
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>