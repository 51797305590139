<template>
  <div class="container mx-auto">
    <div class="flex flex-wrap min-h-screen">
      <div 
        class="px-4"
        :class="{ 
          'w-7/12': isShowSidebar, 
          'w-full': !isShowSidebar
        }"
      >
        <BigTitle title="Settings" class="mb-11 pt-40"/>
        <Labels @addNewLabel="setAddNewLabelVisibility(true)"/>
      </div>
      <div 
        class="px-8 pt-44 z-10 base-bg-darker"
        :class="{ 
          'w-5/12': isShowSidebar, 
          'hidden': !isShowSidebar
        }"
      >
        <AddNewLabel v-if="isShowSidebar" @close="setAddNewLabelVisibility(false)"/>
      </div>
    </div>
  </div>
</template>

<script>
import Labels from '@/components/settings/Labels'
import AddNewLabel from '@/components/settings/AddNewLabel'
import BigTitle from '@/components/BigTitle'

export default {
  name: 'Settings-Labels',
  components: { 
    Labels, 
    AddNewLabel, 
    BigTitle 
  },
  data(){
    return{
      isShowSidebar: false,
    }
  },
  methods: {
    setAddNewLabelVisibility(arg){
      this.isShowSidebar = arg
      if(arg){
        this.$router.replace({ path: '/settings/labels', query: { addNewLabel: true } })
      }else{
        this.$router.replace({ path: '/settings/labels', query: {} })
      }
    }
  },
  mounted(){
    if(this.$route.query.addNewLabel && this.$route.query.addNewLabel == 'true'){
      this.isShowSidebar = true
    }
  }
}
</script>

<style>

</style>